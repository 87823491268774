import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
    render() {
        let style = null
        if (this.props.log === 'yes') {
            style = { position: 'absolute' }

        }
        return (

            <div className="container-fluid" style={{ padding: '20px 0px 0px 0px' }}>
                <div className="row footer inner" style={style}>
                    <div className="col-sm-6">
                        <img style={{ width: '250px' }} src='/fms_logo_signet_corp.png' className="brand" alt="FMS Logistics" />
                    </div>
                    <div className="col-sm-6">
                        <small style={{ float: 'right', paddingTop: '10px' }}>{'© 2019 FR. MEYER’S SOHN (GMBH & CO.) KG'}</small>
                    </div>
                </div>
            </div>
        );
    }
}
export default Footer;