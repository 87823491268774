import React, {Component} from 'react';
import {Route, Redirect} from 'react-router-dom';
import Login from './Login';

class AuthenticatedRoute extends Component{
    render(){
        if((this.props.path ==='/login' || this.props.path === '/') && sessionStorage.getItem('uname') !== null) {
            // return <Redirect to='/search'/>
            return <Redirect to='/home'/>
        } else if (sessionStorage.getItem('uname') !== null) {
            return <Route {...this.props} />
        } else {
            return <Route to='/login' component={Login}/>
        }

    }
}

export default AuthenticatedRoute;