import React from "react";

const InputJobsTemplate = () => {
    return (
        <a className="float-center" href="/TT_InputJobs_Template.xlsx" style={{
            marginTop: '9%',
            display: 'block',
            textAlign: 'center'
        }}>Click to download sample template</a>
    )
}

export default InputJobsTemplate;