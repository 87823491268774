import React from 'react';

const QuoteLineTableHeader = () => {
    return (
        <thead>
            <tr>
                <th className="quote-header">Code</th>
                <th className="quote-header">Description</th>
                <th className="quote-header">Amount</th>
                <th className="quote-header">Currency</th>
                <th className="quote-header">Payment</th>
                <th className="quote-header">Calculation Base</th>
                <th className="quote-header">Inclusive</th>
            </tr>
        </thead>
    )
}


export default QuoteLineTableHeader;