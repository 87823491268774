import axios from 'axios';
import * as appConstants from '../constants/appconstants';

class TrackingsSearchService {
    getOutputTrackings(URL, parameters) {
        axios.defaults.headers.common['Authorization'] = null;
        axios.defaults.headers.common['Authorization'] = appConstants.FMS_TRACKINGS_AUTH_TOKEN;
        return axios.get(URL, { params: { ...parameters } });
    }

    postInutJobs(URL, data) {
        axios.defaults.headers.common['Authorization'] = null;
        axios.defaults.headers.common['Authorization'] = appConstants.FMS_TRACKINGS_AUTH_TOKEN;
        return axios.post(URL, data);
    }
}

export default new TrackingsSearchService()