import React, { Component } from 'react';
import * as AppConstants from '../constants/appconstants';
import Footer from "./Footer";

class ApiDocs extends Component {

    constructor(props) {
        super(props)
        var env = process.env.REACT_APP_ENV;
        if (typeof process.env.REACT_APP_ENV === 'undefined') {
            env = 'localhost';
        }
        this.state = { environment: env }
    }
    render() {
        const swaggerhtml = '/swagger-ui.html'
        const swagger_docs = 'Swagger Docs'
        const stage_docs = <div>
            <ul>
                <li>
                    <a href={`https://fmsratecarrierccg.stage.gcp.fms.de${swaggerhtml}`} target="blank">CCG {swagger_docs}</a>
                </li>
                <li>
                    <a href={`https://fmsratecarrierapl.stage.gcp.fms.de${swaggerhtml}`} target="blank">APL {swagger_docs}</a>
                </li>
                <li>
                    <a href={`https://fmsratecarrierone.stage.gcp.fms.de${swaggerhtml}`} target="blank">ONEY {swagger_docs}</a>
                </li>
                <li>
                    <a href={`https://fmsratecarriermae.stage.gcp.fms.de${swaggerhtml}`} target="blank">MAE {swagger_docs}</a>
                </li>
                <li>
                    <a href={`https://fmsratecarrierevl.stage.gcp.fms.de${swaggerhtml}`} target="blank">EVL {swagger_docs}</a>
                </li>
                <li>
                    <a href={`https://fmsratecarriermsc.stage.gcp.fms.de${swaggerhtml}`} target="blank">MSC {swagger_docs}</a>
                </li>
                <li>
                    <a href={`http://fmscarrierpartnerapi.stage.gcp.fms.de${swaggerhtml}`} target="blank">External Partner API {swagger_docs}</a>
                </li>
                <li>
                    <a href={`https://fmsratecollector.stage.gcp.fms.de${swaggerhtml}`} target="blank">Async Rate Collector {swagger_docs}</a>
                </li>
            </ul>

        </div>

        const prod_docs = <div>
            <ul>
                <li>
                    <a href={`https://fmsratecarrierccg.prod.gcp.fms.de${swaggerhtml}`} target="blank">CCG {swagger_docs}</a>
                </li>
                <li>
                    <a href={`https://fmsratecarrierapl.prod.gcp.fms.de${swaggerhtml}`} target="blank">APL {swagger_docs}</a>
                </li>
				<li>
                    <a href={`https://fmsratecarrierone.prod.gcp.fms.de${swaggerhtml}`} target="blank">ONEY {swagger_docs}</a>
                </li>
                <li>
                    <a href={`https://fmsratecarriermae.prod.gcp.fms.de${swaggerhtml}`} target="blank">MAE {swagger_docs}</a>
                </li>
                <li>
                    <a href={`https://fmsratecarrierevl.prod.gcp.fms.de${swaggerhtml}`} target="blank">EVL {swagger_docs}</a>
                </li>
                <li>
                    <a href={`https://fmsratecarriermsc.stage.gcp.fms.de${swaggerhtml}`} target="blank">MSC {swagger_docs}</a>
                </li>
                <li>
                    <a href={`http://fmscarrierpartnerapi.prod.gcp.fms.de${swaggerhtml}`} target="blank">External Partner API {swagger_docs}</a>
                </li>
                <li>
                    <a href={`https://fmsratecollector.prod.gcp.fms.de${swaggerhtml}`} target="blank">Async Rate Collector {swagger_docs}</a>
                </li>
            </ul>

        </div>

        const dev_docs = <div>
            <ul>
                <li>
                    <a href={`http://${window.location.hostname}:${AppConstants.carrierNodeports.CCG.localhost}${swaggerhtml}`} target="blank">CCG {swagger_docs}</a>
                </li>
                <li>
                    <a href={`http://${window.location.hostname}:${AppConstants.carrierNodeports.APL.localhost}${swaggerhtml}`} target="blank">APL {swagger_docs}</a>
                </li>
                <li>
                    <a href={`http://${window.location.hostname}:${AppConstants.carrierNodeports.MAE.localhost}${swaggerhtml}`} target="blank">MAE {swagger_docs}</a>
                </li>
                <li>
                    <a href={`http://${window.location.hostname}:${AppConstants.carrierNodeports.EVL.localhost}${swaggerhtml}`} target="blank">EVL {swagger_docs}</a>
                </li>
                <li>
                    <a href={`http://${window.location.hostname}:${AppConstants.carrierNodeports.EXT_PRT_API.localhost}${swaggerhtml}`} target="blank">External Partner API {swagger_docs}</a>
                </li>
                <li>
                    <a href={`http://${window.location.hostname}:${AppConstants.carrierNodeports.ASYNC_COLLECTOR.localhost}${swaggerhtml}`} target="blank">Async Rate Collector {swagger_docs}</a>
                </li>
            </ul>

        </div>
        return (
            <div>
                {this.state.environment === 'stage' ? stage_docs : this.state.environment === 'production' ? prod_docs : dev_docs}
                <Footer log={'yes'} />
            </div>
        )
    }
}

export default ApiDocs;