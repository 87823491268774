import React, { Component } from 'react';

import '../App.css';

class PageNotFound extends Component {

    render(){
        return (
            <div>
                <h2 style={{marginTop: '25%', fontSize:'24px'}}>Oops! Requested page not available.</h2>
            </div>
        )
    }
}

export default PageNotFound