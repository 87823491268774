import React, { useState } from 'react'
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table'
import ExportToExcel from '../utils/ExportToExcel';

export default function TrackingLine({ columns, data, heading }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    page,
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    globalFilter,
    setGlobalFilter,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: 25 },
    heading
  },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination);
  const [setFilterInput] = useState("");

  const tableStyle = {
    whiteSpace: 'nowrap',
    height: 'auto',
    display: 'block',
    overflowX: 'auto',
    marginTop: '7px'
  }

  const btnStyle = {
    'fontSize': '13px',
    'margin': '5px 10px',
  }

  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    // setFilter("status", value);
    setGlobalFilter(value);
    // setFilterInput(value);
  }
  // Render the UI for your table
  return (
    <div>
      <hr></hr>
      <div className="col-lg-12" style={{ display: 'inline-flex', justifyContent: 'space-between' }}>
        <h1 style={{ fontSize: '24px', margin: '0px', fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif' }}>{heading}:</h1>
        <input
          value={globalFilter}
          onChange={handleFilterChange}
          placeholder={"Search"}
          className="form-control"
          style={{ width: 'auto' }}
        />
        <ExportToExcel data={data} headers={columns} fileName={heading} />
      </div>
      <table {...getTableProps()} style={tableStyle}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {(column.Header !== 'MainHeader') ? column.render("Header") : 'Records count: ' + rows.length}
                  <span className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "fa fa-fw fa-sort-desc"
                        : "fa fa-fw fa-sort-asc"
                      : ""}></span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* { (pageCount > 1) && */}
      <div>
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          style={btnStyle}
        >
          First
        </button>
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          style={{
            'fontSize': '13px',
            'margin': '5px 10px 5px 0px ',
          }}
        >
          Previous
        </button>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          style={{
            'fontSize': '13px',
            'margin': '5px 0px 5px 10px',
          }}
        >
          Next
        </button>
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          style={btnStyle}
        >
          Last
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
          {' '}
        </span>
        <select
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          {[25, 50, 100, 500, 1000].map(
            pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            )
          )}
        </select>
      </div>

    </div>
  );
}

