import React, { Component } from "react";
import Footer from "../Footer";
import XLSX from "xlsx";
import Utils from "../utils/Utils";
import TrackingsSearchService from "../../services/TrackingsSearchService";
import * as AppConstants from "../../constants/appconstants";
import InsertJobsGrid from "./InsertJobsGrid";
import InputJobsTemplate from "./InputJobsTemplate";

const initialState = { errors: [], jobs: [], msgs: [] }
class InsertJobs extends Component {

    constructor(props) {
        super(props)

        let jobsInsertUrl = AppConstants.EXT_PRT_API_INPUT_INSERT_DEV;
        console.log(`App is running in '${process.env.REACT_APP_ENV}' environmet.`)
        if (process.env.REACT_APP_ENV === 'stage') {
            jobsInsertUrl = AppConstants.EXT_PRT_API_INPUT_INSERT_STAGE;
        } else if (process.env.REACT_APP_ENV === 'production') {
            jobsInsertUrl = AppConstants.EXT_PRT_API_INPUT_INSERT_PROD;
        }

        this.state = { ...initialState, jobsUrl: jobsInsertUrl, isLoading: false };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.insertJobs = this.insertJobs.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleGridJobs = this.handleGridJobs.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ errors: [], msgs: [], isLoading: true });
        let files = e.target[0].files;
        let xlFile = files[0];
        if (xlFile.name.toLowerCase().endsWith('.xlsx') || xlFile.name.toLowerCase().endsWith('.ods')) {
            let reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = e => {
                var data = e.target.result;
                let readedData = XLSX.read(data, { type: rABS ? 'binary' : 'array' });
                const wsName = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsName];
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

                let valRes = Utils.validateExcelInputJobs(dataParse);
                if (valRes.errs.length !== 0) {
                    this.setState({ errors: valRes.errs })
                } else {
                    // this.setState({ jobs: valRes.dt })
                    console.log(valRes.dt);
                    this.insertJobs(valRes.dt)
                }
            };
            if (rABS) reader.readAsBinaryString(xlFile); else reader.readAsArrayBuffer(xlFile);
        } else {
            this.setState({ errors: ['Supported files formats ".xlsx, .ods"'] });
        }
    }

    insertJobs(jobs) {
        TrackingsSearchService.postInutJobs(this.state.jobsUrl, jobs).then(
            response => {
                console.log(response);
                if (response.status === 200) {
                    this.setState({ msgs: [response.data.details], isLoading: false });
                }
            }
        ).catch(
            error => {
                console.log(error);
                if (typeof error.response === 'undefined') {
                    this.setState({ errors: [error.message], isLoading: false });
                } else {
                    this.setState({ errors: [error.response.data.details], isLoading: false });
                }
            }
        );
    }


    resetForm() {
        this.setState({ ...initialState, isLoading: false })
    }

    handleGridJobs(gridJobs) {
        console.log(gridJobs);
        this.setState({ errors: [], msgs: [], isLoading: false });
        let errs = Utils.validateInputJobs(gridJobs);
        if (errs.length !== 0) {
            this.setState({ errors: errs });
        } else {
            this.insertJobs(gridJobs);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid container-main" style={{ borderColor: '1px' }}>

                    <h1 style={{ fontSize: '24px' }}>T&T Input jobs - Insert:</h1>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row" style={{ marginLeft: '10px' }}>
                            <div className="col-6" style={{ border: '1px solid black' }}>
                                <div className="form-group my-5" style={{ marginTop: '10px' }}>
                                    {/* <label className="ml-2 mt-1" htmlFor="uploadJobs">Upload Jobs: </label> */}

                                    <input type="file" className="w-auto" id="customFile" required={true} style={{ display: 'inline', width: '50%' }} />
                                    {/* <label class="custom-file-label" for="customFile">Choose file</label> */}
                                    <button type="submit" className="btn btn-outline-secondary float-center" style={{ marginLeft: '15%' }}>Upload</button>
                                    <button type="reset" className="btn btn-outline-link float-right mr-5" onClick={this.resetForm}>Reset</button>
                                </div>
                            </div>
                            <div className="col-6" style={{ height: '136px', overflow: 'auto' }}>
                                {this.state.errors.length > 0 ?
                                    <div style={{ marginLeft: '22px', color: 'red' }}>
                                        <strong>Error(s):</strong>
                                        {this.state.errors.map((err, i) => (
                                            <li key={i}>{err}</li>)
                                        )}
                                    </div>
                                    : <div>
                                        {this.state.msgs.length > 0 ?
                                            <div style={{ marginLeft: '22px' }}>
                                                <strong>Message(s):</strong>
                                                {this.state.msgs.map((err, i) => (
                                                    <li key={i}>{err}</li>)
                                                )}
                                            </div> : <div>{this.state.isLoading ? <img style={{ marginLeft: '50%', marginTop: '7%' }} src="/loading-spinner.gif" alt="Loading" width="20px" /> : <InputJobsTemplate />}</div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>

                    </form>
                    <br></br>
                    <hr></hr>
                    <InsertJobsGrid onSubmitJobs={this.handleGridJobs} onResetGridForms={this.resetForm} />
                </div>
                <Footer log={'yes'} />
            </React.Fragment >
        );
    }

}

export default InsertJobs;