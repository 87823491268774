import React, { Component } from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelSheet;
const ExcelColumn = ReactExport.ExcelColumn;

class ExportToExcel extends Component {
    render(){
        return(
           
            <ExcelFile filename={this.props.fileName} element={<button className="btn btn-outline-secondary" >Export to Excel</button>}>
                <ExcelSheet data = {this.props.data} name="Trackings">
                    {this.props.headers[0].columns.map(
                        (column, i) => 
                        (typeof column.Header !== 'undefined' && typeof column.accessor !== 'undefined') && <ExcelColumn label={column.Header} value={column.accessor} />
                    )}
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default ExportToExcel;
