import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';
import Header from './components/Header';
import Login from './components/Login';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import PageNotFound from './components/PageNotFound';
import ApiDocs from './components/ApiDocs';
import Home from './components/Home';
import TrackingSearch from "./components/ttsearch/TrackingSearch";
import InputJobsSearch from "./components/ttsearch/InputJobsSearch";
import InsertJobs from './components/ttsearch/InsertJobs';
import QuoteSearch from './components/quotesearch/QuoteSearch';

class App extends Component {



  render() {

    return (
      <React.Fragment>
        <Router>
          <Header />
          <Switch>
            <AuthenticatedRoute exact path='/' component={Login} />
            <AuthenticatedRoute exact path='/login' component={Login} />
            <AuthenticatedRoute exact path='/quotes' component={QuoteSearch} />
            <AuthenticatedRoute exact path='/apidocs' component={ApiDocs} />
            <AuthenticatedRoute exact path='/ttoutput' component={TrackingSearch} />
            <AuthenticatedRoute exact path='/ttinput' component={InputJobsSearch} />
            <AuthenticatedRoute exact path='/ttinsert' component={InsertJobs} />
            <AuthenticatedRoute exact path='/home' component={Home} />
            <Route path='/**' component={PageNotFound} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
export default App;
