import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import AuthenticationService from '../services/AuthenticationService';

class Header extends Component {


    render() {

        const style = {
            color: 'rgb(178,6,41)'
        }
        return (

            <div style={{ paddingRight: '0px' }} >
                <div>
                    <nav className="navbar navbar-dark" style={{ height: '74px' }}>
                        <Link className="navbar-brand" style={style} to="/">SpotQuote, T&T Search  {process.env.REACT_APP_ENV === 'stage' && <strong> - stage</strong>}</Link>
                    </nav>
                    <nav className="navbar" style={{ backgroundColor: 'rgb(178,6,41)' }}>
                        <div className="container-fluid">
                            {(sessionStorage.getItem('uname') !== null) ? window.location.pathname === '/home' ?
                                <ul className="nav navbar-nav" style={{ 'display': 'contents' }}>
                                    <li><Link style={{ color: 'white', fontWeight: '700' }} to="/">Home</Link></li>
                                </ul>
                                :
                                <ul className="nav navbar-nav" style={{ 'display': 'contents' }}>
                                    <li><Link style={{ color: 'white', fontWeight: '700' }} to="/">Home</Link></li>
                                    <li className="dropdown"> <a className="dropdown-toggle" role="button" data-toggle="dropdown" style={{ color: 'white', fontWeight: '700', cursor: 'pointer' }} to="#">Trackings<span className="caret"></span></a>
                                        <ul className="dropdown-menu" style={{ position: 'absolute' }}>
                                            <li className="dropdown-item"><Link style={{ fontWeight: '500', color: 'rgb(178,6,41)' }} to="/ttinsert">Insert jobs</Link></li>
                                            <li className="dropdown-item"><Link style={{ fontWeight: '500', color: 'rgb(178,6,41)' }} to="/ttinput">Search jobs - Input</Link></li>
                                            <li className="dropdown-item"><Link style={{ fontWeight: '500', color: 'rgb(178,6,41)' }} to="/ttoutput">Search jobs - Output</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link style={{ color: 'white', fontWeight: '700' }} to="/quotes">Quotations</Link></li>
                                    <Link style={{ color: 'white', fontWeight: '700' }} to="/apidocs">API Docs</Link>
                                </ul>
                                :
                                <ul className="nav navbar-nav" style={{ 'display': 'contents' }}>
                                    <li><Link style={{ color: 'white', fontWeight: '700' }} to="#">Login</Link></li>
                                </ul>}

                            <ul className="nav navbar-nav navbar-right">
                                {sessionStorage.getItem('uname') !== null &&
                                    <Link style={{ color: 'white', fontWeight: '700' }} to="/login" onClick={AuthenticationService.userLogout}>Logout</Link>}
                            </ul>
                        </div>
                    </nav>



                    {/* <nav className="navbar navbar-expand-lg" style={{ backgroundColor: 'rgb(178,6,41)' }}>
                        {(sessionStorage.getItem('uname') !==null && window.location.pathname === '/home') ? <Link className="navbar-brand" style={{ color: 'white', fontWeight: '700' }} to="/">Home</Link>: <div className="collapse navbar-collapse">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item"><Link className="nav-link" style={{ color: 'white', fontWeight: '700' }} to="/">Home</Link></li>
                                <li className="nav-item">
                                <Link className="nav-link" style={{ color: 'white', fontWeight: '700' }} to="/search">Quotations</Link>    
                                </li>
                                <li className="nav-item">
                                <Link className="nav-link" style={{ color: 'white', fontWeight: '700', fontSize: 'inherit' }} to="/ttsearch">T&T Search</Link>
                                </li>
                                <li className="nav-item">
                                <Link className="nav-link" style={{ color: 'white', fontWeight: '700', fontSize: 'inherit' }} to="/apidocs">API Docs</Link>    
                                </li>
                            </ul>
                            </div>
                        }
                        {sessionStorage.getItem('uname') !==null && 
                        <Link className="navbar-brand" style={{ color: 'white', fontWeight: '700' }} to="/login" onClick={AuthenticationService.userLogout}>Logout</Link>
                    }
                    </nav> */}

                    {/* <div>
                        <nav className="navbar navbar-dark" style={{ backgroundColor: 'rgb(178,6,41)' }}>
                        {(sessionStorage.getItem('uname') !==null && window.location.pathname === '/home') ? <Link className="navbar-brand" style={{ color: 'white', fontWeight: '700' }} to="/">Home</Link>: <div className="col-md-6">
                            <Link className="nav-item" style={{ color: 'white', fontWeight: '700' }} to="/">Home</Link>
                            <Link className="navbar-brand" style={{ color: 'white', fontWeight: '700' }} to="/search">Quotations</Link>
                            
                            <Link className="navbar-brand" style={{ color: 'white', fontWeight: '700', fontSize: 'inherit' }} to="/ttsearch">T&T Search</Link>
                            <Link className="navbar-brand" style={{ color: 'white', fontWeight: '700', fontSize: 'inherit' }} to="/apidocs">API Docs</Link>
                            </div>
                        }
                        {sessionStorage.getItem('uname') !==null && 
                        <Link className="navbar-brand" style={{ color: 'white', fontWeight: '700' }} to="/login" onClick={AuthenticationService.userLogout}>Logout</Link>
                    }
                    </nav>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default withRouter(Header);