import React, { Component } from 'react';
import AuthenticationService from '../services/AuthenticationService';
import Footer from './Footer';

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            error: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.isDisabled = this.isDisabled.bind(this)
    }

    handleSubmit(event) {
        event.preventDefault()
        let isLoggedin = AuthenticationService.userLogin({uname:this.state.username, pwd:this.state.password})
        if (isLoggedin) {
            sessionStorage.setItem('uname', this.state.username)
            this.props.history.push('/home')
        } else {
            if(this.state.username === ''){
                this.setState({error:"Please enter Username"})    
            }
            this.setState({error:"Invalid username/password!"})
            // console.log("Invalid Login")
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        }
        )
        this.setState({error:''})
    }

    isDisabled(){
        if(this.state.username === '' & this.state.password === ''){
            return true;
        } else {
            return false;
        }
    }

    render() {
        const isEnabled = this.state.username.length >0 && this.state.password.length >0
        const isFF = 'MozAppearance' in document.documentElement.style;
        let formStyle = {}
        if(isFF){
            formStyle = {
                marginLeft: '40%'
            }
        }
        return (

            <React.Fragment>
            <div className="container-fluid container-main" style={{width:'fit-content'}}>
                <div><p style={{color:'red'}}>{this.state.error}</p></div>
                <form onSubmit={this.handleSubmit} style={formStyle}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <p>User Name:</p>
                                </td>
                                <td>
                                    <input type="text" name="username" onChange={this.handleChange} />
                                    <span></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Password:</p>
                                </td>
                                <td>
                                    <input type="password" name="password" onChange={this.handleChange} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                </td>
                                <td>
                                    <input type="submit" value="Login" disabled={!isEnabled}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </form>
            </div>
            <Footer log='yes' /></React.Fragment>
        )
    }
}

export default Login;