import React, { Component } from "react";
import DateTimePicker from "react-datetime-picker";
import * as ccodes from "../../constants/ttCarrierCodes";
import Footer from "../../components/Footer";
import TrackingLine from './TrackingLine';
import TrackingsSearchService from '../../services/TrackingsSearchService';
import StatefulMultiSelect from "@khanacademy/react-multi-select";
import * as AppConstants from '../../constants/appconstants';
import Utils from "../utils/Utils";

const _ = require("lodash")
const toDay = new Date();
const initialSearchInputs = {
  carriers: [],
  bkRef: '',
  blRef: '',
  cntrNum: [],
  sid: null,
  startPt: '',
  endPt: toDay.toISOString().slice(0, 10),
  startCt: '',
  endCt: toDay.toISOString().slice(0, 10),
  epid: ''
};


class TrackingSearch extends Component {

  constructor(props) {
    super(props);

    let oldDate = new Date();
    oldDate.setMonth(oldDate.getMonth() - 3)

    let outputFetchApiUrl = AppConstants.EXT_PRT_API_FETCH_DEV;
    // console.log(`App is running in '${process.env.REACT_APP_ENV}' environmet.`)
    if (process.env.REACT_APP_ENV === 'stage') {
      outputFetchApiUrl = AppConstants.EXT_PRT_API_FETCH_STAGE;
    } else if (process.env.REACT_APP_ENV === 'production') {
      outputFetchApiUrl = AppConstants.EXT_PRT_API_FETCH_PROD;
    }


    this.state = {
      ttsearchinputs: { ...initialSearchInputs, startCt: oldDate, startPt: oldDate },
      trackings: [],
      errors: [],
      isdataloading: false,
      footType: 'yes',
      outputFetchApi: outputFetchApiUrl,
      back3MntsDate: oldDate
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.getTrackings = this.getTrackings.bind(this);
    this.toggleSpinner = this.toggleSpinner.bind(this);
    this.convertDateToString = this.convertDateToString.bind(this);
    this.handleClearRage = this.handleClearRage.bind(this);
  }

  toggleSpinner() {
    this.setState({ isdataloading: !this.state.isdataloading })
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ footType: 'yes', trackings: [] })
    this.toggleSpinner();

    this.getTrackings();

  }

  convertDateToString(dt) {
    if (typeof dt !== 'undefined' && dt !== '') {
      let d = new Date(dt);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }
    return null;
  }

  async getTrackings() {
    let ttinputs = { ...this.state.ttsearchinputs }
    let errors = [];
    let params = {
      ...ttinputs, carriers: ttinputs.carriers.toString(),
      startPt: this.convertDateToString(ttinputs.startPt),
      endPt: this.convertDateToString(ttinputs.endPt),
      startCt: this.convertDateToString(ttinputs.startCt),
      endCt: this.convertDateToString(ttinputs.endCt)
    }
    // console.log(params);
    this.setState({ errors: [] })
    let validationErrors = await Utils.validateFormInputs({ ...this.state.ttsearchinputs });
    // console.log(this.state.outputFetchApi);
    if (validationErrors.length === 0) {
      TrackingsSearchService.getOutputTrackings(this.state.outputFetchApi, params).then(
        response => {

          // console.log(response);
          if (response.status === 200 && response.data.length !== 0) {
            this.setState({ trackings: response.data, errors: [] });
            this.setState({ footType: '' })
            this.toggleSpinner();
          } else {
            this.toggleSpinner();
            this.setState({ errors: ["No trackings found!"], trackings: [] })
            this.setState({ footType: 'yes' })
          }
        }
      ).catch(
        err => {
          console.log(err);
          if (typeof err.response === 'undefined') {
            errors.push(err.message);
            this.toggleSpinner();
            this.setState({ errors: [err.message], trackings: [] })
          } else {
            // if (err.response.status === 400) 
            errors.push(err.response.data.details);
            this.setState({ errors: [err.response.data.details], trackings: [] })
            this.toggleSpinner();
          }
        }
      );
    } else {
      this.setState({ errors: validationErrors })
      this.toggleSpinner();
    }
  }

  handleChange(e) {
    let inputName = e.target.name;
    let tmpInputs = this.state.ttsearchinputs;
    this.setState({
      ttsearchinputs: {
        ...tmpInputs,
        [inputName]: e.target.value
      }
    })
  }


  handleMultiSelectChange(e) {
    this.setState({ ...this.state, ttsearchinputs: { ...this.state.ttsearchinputs, carriers: [...e.selected] } })
  }

  handleClearRage(e) {
    if (e.target.id === 'ct') {
      this.setState({ ttsearchinputs: { ...this.state.ttsearchinputs, startCt: '', endCt: '' } })
    }
    if (e.target.id === 'pt') {
      this.setState({ ttsearchinputs: { ...this.state.ttsearchinputs, startPt: '', endPt: '' } })
    }

  }

  render() {
    const { selected } = { selected: this.state.ttsearchinputs.carriers };
    return (
      <React.Fragment>

        <div className="container-fluid container-main" style={{ borderColor: '1px' }}>
          {/*<div > */}
          <form onSubmit={this.handleSubmit}>
            <h1 style={{ fontSize: '24px' }}>T&T Output Search Form:</h1> {/* , marginLeft: '17%' */}
            <div className="row" style={{ marginLeft: '10px' }}>   {/*  style={{justifyContent: 'center'}} */}
              <div className="col-4" style={{ border: '1px solid black', borderRightWidth: 'inherit', borderRightStyle: 'unset' }}>
                <div className="form-group row" style={{ marginTop: '10px' }}>
                  <label htmlFor="carriers" className="col-sm-4 col-form-label">Carriers:</label>
                  <div className="col-sm-5">
                    <StatefulMultiSelect
                      overrideStrings={{
                        selectSomeItems: "Select Carriers...",
                        allItemsAreSelected: "All carriers are Selected",
                        selectAll: "Select All",
                        search: "Search",
                      }}
                      options={ccodes.TT_CARRIERS_JSON}
                      selected={selected}
                      onSelectedChanged={selected => this.handleMultiSelectChange({ selected })}
                      id="carriers"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="bkRef" className="col-sm-4 col-form-label"> Booking Reference:</label>
                  <div className="col-sm-5">
                    <input className="form-control" id="bkRef" type="text" name="bkRef" value={this.state.ttsearchinputs.bkRef || ''} onChange={this.handleChange} />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="blRef" className="col-sm-4 col-form-label"> Bill of Lading:</label>
                  <div className="col-sm-5">
                    <input className="form-control" id="blRef" type="text" name="blRef" value={this.state.ttsearchinputs.blRef || ''} onChange={this.handleChange} />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="cntrNum" className="col-sm-4 col-form-label"> Container Nums:</label>
                  <div className="col-sm-5">
                    <input className="form-control" id="cntrNum" type="text" name="cntrNum" value={this.state.ttsearchinputs.cntrNum || ''} onChange={this.handleChange} />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="sid" className="col-sm-4 col-form-label"> Shipment Id:</label>
                  <div className="col-sm-5">
                    <input className="form-control" id="sid" type="text" name="sid" type="number" value={this.state.ttsearchinputs.sid || ''} onChange={this.handleChange} />
                  </div>
                </div>
              </div>

              <div className="col-4" style={{ border: '1px solid black', borderLeftWidth: 'inherit', borderLeftStyle: 'unset' }}>
                <div className="form-group row">
                  <label htmlFor="pt" className="col-sm-6 col-form-label">Processing Timestamp range:</label>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12" id="ct" style={{ display: 'inline-flex' }}>
                    <DateTimePicker id="startPt" name="startPt" className="form-control" format="yyyy-MM-dd" yearPlaceholder='YYYY' dayPlaceholder='dd' monthPlaceholder='MM' clearIcon={null} disableClock={true} value={this.state.ttsearchinputs.startPt || ''} onChange={spt_date => this.setState({ ttsearchinputs: { ...this.state.ttsearchinputs, startPt: spt_date } })} />
                    <span><strong>&nbsp;&nbsp;_&nbsp;&nbsp;</strong></span>
                    <DateTimePicker id="endPt" name="endPt" className="form-control" format="yyyy-MM-dd" yearPlaceholder='YYYY' dayPlaceholder='dd' monthPlaceholder='MM' clearIcon={null} disableClock={true} value={this.state.ttsearchinputs.endPt || ''} onChange={spt_date => this.setState({ ttsearchinputs: { ...this.state.ttsearchinputs, endPt: spt_date } })} /> {/* calendarIcon={true} to disable calender icon */}
                    &nbsp;&nbsp;&nbsp;<i className="fa fa-times" name="pt" id="pt" style={{ marginTop: '2%' }} onClick={e => this.handleClearRage(e)}></i>
                  </div>
                </div>


                <div className="form-group row">
                  <label htmlFor="ct" className="col-sm-6 col-form-label">Creation Timestamp range:</label>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12" id="ct" style={{ display: 'inline-flex' }}>
                    <DateTimePicker id="startCt" name="startCt" className="form-control" calendarClassName="datepicker" format="yyyy-MM-dd" yearPlaceholder='YYYY' dayPlaceholder='dd' monthPlaceholder='MM' clearIcon={null} disableClock={true} value={this.state.ttsearchinputs.startCt || ''} onChange={spt_date => this.setState({ ttsearchinputs: { ...this.state.ttsearchinputs, startCt: spt_date } })} />
                    <span><strong>&nbsp;&nbsp;_&nbsp;&nbsp;</strong></span>
                    <DateTimePicker id="endCt" name="endCt" className="form-control" format="yyyy-MM-dd" yearPlaceholder='YYYY' dayPlaceholder='dd' monthPlaceholder='MM' clearIcon={null} disableClock={true} value={this.state.ttsearchinputs.endCt || ''} onChange={spt_date => this.setState({ ttsearchinputs: { ...this.state.ttsearchinputs, endCt: spt_date } })} />
                    &nbsp;&nbsp;&nbsp;<i className="fa fa-times" name="ct" id="ct" style={{ marginTop: '2%' }} onClick={e => this.handleClearRage(e)}></i>
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="epid" className="col-sm-5 col-form-label">External Partner Id:</label>
                  <div className="col-sm-5">
                    <input className="form-control" id="epid" name="epid" value={this.state.ttsearchinputs.epid || ''} onChange={this.handleChange} />
                  </div>
                </div>
                <div>
                </div>


                <div className="form-group" > {/* justifyContent: 'center' */}
                  <input className="btn btn-outline-dark offset-2" value="Search" type="submit" disabled={_.isEqual(this.state.ttsearchinputs, initialSearchInputs)} />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input className="btn btn-link" value="Reset" type="reset" onClick={() => {
                    {
                      let searchInputs = { ...initialSearchInputs, startPt: this.state.back3MntsDate, endPt: toDay, startCt: this.state.back3MntsDate, endCt: toDay }
                      this.setState({ ttsearchinputs: searchInputs, trackings: [], footType: 'yes', errors: [], isdataloading: false })
                    }
                  }} />
                </div>
              </div>


              <div className="col-4" style={{ height: '374px', overflow: 'auto' }}>
                {this.state.errors.length > 0 ?
                  "No trackings found!".localeCompare(this.state.errors[0]) === 0 ?
                    <div style={{ marginLeft: '22px', color: 'black' }}>
                      {this.state.errors.map((err, i) => (<strong><li key={i}>Message: {err}</li></strong>))}
                    </div>
                    :
                    <div style={{ marginLeft: '22px', color: 'red' }}>
                      <strong>Error(s):</strong>
                      {this.state.errors.map((err, i) => (
                        <li key={i}>{err}</li>)
                      )}
                    </div> : <></>
                }
              </div>
            </div>
          </form>
        </div>

        <div>
          {(this.state.trackings.length > 0) &&
            <div>
              <TrackingLine columns={AppConstants.columnHeaders} data={this.state.trackings} heading="Container Trackings" />
            </div>}
        </div>
        {this.state.isdataloading && <div><h1 style={{ fontSize: '24px', margin: '0px', fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif' }}>Container Trackings</h1><img style={{ marginLeft: '50%' }} src="/loading-spinner.gif" alt="Loading" width="20px" /></div>}

        <Footer log={this.state.footType} />
      </React.Fragment>
    )
  }
}

export default TrackingSearch;