export const TT_CARRIER_CODES = { ttcodes:[
	  'ACL','ALI','ANL','APL','AR1','CCG','CGA','COC','CCRSJO','CNCTPE','CULSHA','DAL','ECU','EMI1','ETSL','ETH','EVL','GRL','GS1','HASSEL','HLL','HMM','HSG','IALTYO','ICL','KM1','KAW','MA1','MAC','MAE','MAF','MARSIN','MATHNL', 'MCC','MSC','NAMSEL', 'NID','PIL','POCSEL','ONEY','OOC','RCL','SAC','SAB','SAR','SAF','SCI','SEAGO','SEAMMR','SITC','SMLSEL','SMMSEL','TASDEL','TSLTYO', 'UNF','WAA','WAL','WEC','YML','NVOINT','SH1','ZIM'
]}

export const TT_CARRIERS_JSON = [{label: 'ACL', value: 'ACL'},
{label: 'ALI', value: 'ALI'},
{label: 'ANL', value: 'ANL'},
{label: 'APL', value: 'APL'},
{label: 'AR1', value: 'AR1'},
{label: 'CCG', value: 'CCG'},
{label: 'CGA', value: 'CGA'},
{label: 'COC', value: 'COC'},
{label: 'CCRSJO', value: 'CCRSJO'},
{label: 'CNCTPE', value: 'CNCTPE'},
{label: 'CULSHA', value: 'CULSHA'},
{label: 'DAL', value: 'DAL'},
{label: 'ECU', value: 'ECU'},
{label: 'EMI1', value: 'EMI1'},
{label: 'ETSL', value: 'ETSL'},
{label: 'ETH', value:'ETH'},
{label: 'EVL', value: 'EVL'},
{label: 'GRL', value: 'GRL'},
{label: 'GS1', value: 'GS1'},
{label: 'HASSEL', value: 'HASSEL'},
{label: 'HLL', value: 'HLL'},
{label: 'HMM', value: 'HMM'},
{label: 'HSG', value: 'HSG'},
{label: 'IALTYO', value: 'IALTYO'},
{label: 'ICL', value: 'ICL'},
{label: 'KM1', value: 'KM1'},                              
{label: 'KAW', value: 'KAW'},
{label: 'MA1', value: 'MA1'},
{label: 'MAC', value: 'MAC'},
{label: 'MAE', value: 'MAE'},
{label: 'MAF', value: 'MAF'},
{label: 'MARSIN', value: 'MARSIN'},
{label: 'MATHNL', value: 'MATHNL'},
{label: 'MCC', value: 'MCC'},
{label: 'MSC', value: 'MSC'},
{label: 'NAMSEL', value: 'NAMSEL'},
{label: 'NID', value: 'NID'},
{label: 'ONEY', value: 'ONEY'},
{label: 'OOC', value: 'OOC'},
{label: 'PIL', value: 'PIL'},
{label: 'POCSEL', value: 'POCSEL'},
{label: 'RCL', value: 'RCL'},
{label: 'SAB', value: 'SAB'},
{label: 'SAC', value: 'SAC'},
{label: 'SAF', value: 'SAF'},
{label: 'SAR', value: 'SAR'},
{label: 'SCI', value: 'SCI'},
{label: 'SEAG', value: 'SEAG'},
{label: 'SEAMMR', value: 'SEAMMR'},
{label: 'SITC', value: 'SITC'},
{label: 'SMLSEL', value: 'SMLSEL'},
{label: 'SMMSEL', value: 'SMMSEL'},
{label: 'TASDEL', value: 'TASDEL'},
{label: 'TSLTYO', value: 'TSLTYO'},
{label: 'UNF', value: 'UNF'},
{label: 'WAA', value: 'WAA'},
{label: 'WAL', value: 'WAL'},
{label: 'WEC', value: 'WEC'},
{label: 'YML', value: 'YML'},
{label: 'ZIM', value: 'ZIM'},
{label: 'NVOINT', value: 'NVOINT'},
{label: 'SH1', value: 'SH1'},]

export const TT_INPUT_STATUS = [
    {label: 'ok', value: 'ok'},
    {label: 'error', value: 'error'},
    {label: 'null', value: 'null'},
]