import React from 'react';

const QuotesTableHeader = () => {
    return (
        <thead>
            <tr>
                <th className="quote-header">Carrier</th>
                <th className="quote-header">Reference</th>
                <th className="quote-header">POL</th>
                <th className="quote-header">POD</th>
                <th className="quote-header">Commodity</th>
                <th className="quote-header">ETS</th>
                <th className="quote-header">Valid From</th>
                <th className="quote-header">Valid To</th>
                <th className="quote-header">All-In Rate</th>
                <th className="quote-header">Rate Type</th>
                <th className="quote-header">Currency</th>
                <th className="quote-header">Container Type</th>
            </tr>
        </thead>
    )
}

export default QuotesTableHeader;