import React, { Component } from 'react';
import Surcharges from './Surcharges';
import QuoteLineTableHeader from './QuoteLineTableHeader';
// import LoadingSpinner from './LoadingSpinner';

class Quotes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isHidden: true
        }
        this.hideHandler = this.hideHandler.bind(this);
    }
    hideHandler() {
        this.setState({ isHidden: !this.state.isHidden })
    }

    render() {
        return (
            <React.Fragment>
                <tr onClick={this.hideHandler} style={{ cursor: 'pointer' }}>
                    <td>{this.props.quote.carrier}</td>
                    <td>{this.props.quote.contractNumber}</td>
                    <td>{this.props.quote.pol}</td>
                    <td>{this.props.quote.pod}</td>
                    <td>{this.props.quote.commodity}</td>
                    <td>{this.props.quote.ets}</td>
                    <td>{this.props.quote.validFrom}</td>
                    <td>{this.props.quote.validTo}</td>
                    <td>{this.props.quote.allInRate}</td>
                    <td>{this.props.quote.rateType}</td>
                    <td>{this.props.quote.allInCurrency}</td>
                    <td>{this.props.quote.containerType}</td>
                </tr>
                <tr style={{ display: this.state.isHidden ? 'none' : 'contents' }}>
                    <td colSpan="12" >
                        <table style={{ marginLeft: '10%', border: '1px' }}  >
                            <QuoteLineTableHeader />
                            <tbody>
                                {this.props.quote.surcharges !== null && this.props.quote.surcharges.map(
                                    (surcharges, index) => <Surcharges surcharges={surcharges} key={index} />
                                )}
                            </tbody>
                        </table>
                    </td>
                </tr>
                {/* {this.props.dataLoad ? <tr><LoadingSpinner /></tr> : <tr></tr>} */}
            </React.Fragment>
        );
    }
}

export default Quotes;