import axios from 'axios';
import * as appconstants from '../constants/appconstants';

const https = require('https')
const API_URL = "http://localhost:8441/api/fms/scraping/v2/rate/"

const carrierAPIs = [
    {
        name: 'ccg',
        // url: 'https://localhost:8441/api/fms/scraping/v2/rate/ccg'
        url: `http://${window.location.hostname}:32124/api/fms/scraping/v2/rate/ccg`
    },
    {
        name: 'apl',
        url: `http://${window.location.hostname}:32086/api/fms/scraping/v2/rate/apl`
        // url: 'https://localhost:8442/api/fms/scraping/v2/rate/apl'
    },
    {
        name: 'evl',
        url: `http://${window.location.hostname}:32217/api/fms/scraping/v2/rate/evl`
        // url: 'https://localhost:8443/api/fms/scraping/v2/rate/evl'
    },
    {
        name: 'mae',
        // url: `http://${window.location.hostname}:31786/api/fms/scraping/v2/rate/maersk`
        url: 'http://localhost:8443/api/fms/scraping/v2/rate/maersk'
    }
];


class QuotationSearchService {

    getQuotesData(URL, data) {
        axios.defaults.headers.common['Authorization'] = null;
        axios.defaults.headers.common['Authorization'] = appconstants.Auth_Token
        const agent = new https.Agent({
            // cert: fs.readFileSync("/keystore.p12"),
            // key: fs.readFileSync("/keystore.p12"),
            // passphrase: "fmslogistics",
            rejectUnauthorized: false
        });
        return axios.post(URL, data, { httpsAgent: agent, Authorization: appconstants.Auth_Token });
    }

    getQuotations(data) {
        // console.log(`BODY: ${data}`)
        // const agent = new https.Agent({
        //     rejectUnauthorized: false
        // })
        const instance = axios.create({
            httpsAgent: new https.Agent({
                rejectUnauthorized: false
            })
        });
        return instance.post(`${API_URL}ccg`, data);

        // axios.post(`${API_URL}ccg`, data).then(
        //     response => {
        //         console.log(response.data)
        //     }   
        // )
        // .catch(
        //     reject => {
        //         console.log(reject.data)
        //     }
        // )
    }

    getAllCarrierUrls() {
        return carrierAPIs;
    }
}

export default new QuotationSearchService()