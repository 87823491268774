import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";

class Home extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid container-main" style={{ width: 'fit-content' }}>
                    <div style={{ 'textAlignLast': 'center', 'paddingTop': '20%' }}>
                        <Link className="btn btn-info" style={{ border: '1px' }} to="/quotes">Quotes Search</Link>&nbsp;&nbsp;&nbsp;
                        <Link className="btn btn-warning" to="/ttinsert">Insert T&T jobs</Link>&nbsp;&nbsp;&nbsp;
                        <Link className="btn btn-secondary" to="/ttinput">Search T&T jobs - input</Link>&nbsp;&nbsp;&nbsp;
                        <Link className="btn btn-secondary" to="/ttoutput">Search T&T jobs - output</Link>&nbsp;&nbsp;&nbsp;
                        <Link className="btn btn-link" to="/apidocs">API Swagger Docs</Link>&nbsp;&nbsp;&nbsp;
                    </div>
                </div>
                <Footer log='yes' />
            </React.Fragment>
        );
    }

}

export default Home;