import React, { Component } from "react";

const initialRows = [{
    carrier: '',
    booking_ref: '',
    bl_ref: '',
    container_num: '',
    min_date: '',
    externalid: ''
}]

class InsertJobsGrid extends Component {

    constructor(props) {
        super(props);
        this.state = { jobs: initialRows.slice() }

        this.handleChange = this.handleChange.bind(this);
        this.addEmptyJob = this.addEmptyJob.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResetGrid = this.handleResetGrid.bind(this);

    }

    handleChange(e) {
        // console.log(e.target.name + ', ' + e.target.value)
        const index = e.target.name.split('-')[0];
        const name = e.target.name.split('-')[1];
        let jobs = this.state.jobs;
        jobs[index] = { ...jobs[index], [name]: e.target.value }
        this.setState({ jobs: jobs })

    }

    addEmptyJob() {
        // let jobs = this.state.jobs;
        // jobs.push(initialRows.slice())
        this.setState({ jobs: [...this.state.jobs, ...initialRows.slice()] })
    }
    handleSubmit() {
        this.props.onSubmitJobs(this.state.jobs)
    }

    handleResetGrid() {
        this.setState({ jobs: initialRows.slice() });
        this.props.onResetGridForms(true);
    }
    render() {
        return (
            <div className="row" style={{ marginRight: '10px' }}>
                <div className="form-group col-12 table-no-broder" style={{ marginBottom: '7%', border: '1px solid black' }}>
                    <table className="table-no-broder">
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'center' }}>Row</th>
                                <th style={{ textAlign: 'center' }}>Carrier</th>
                                <th style={{ textAlign: 'center' }}>Booking Ref</th>
                                <th style={{ textAlign: 'center' }}>Bill of Lading</th>
                                <th style={{ textAlign: 'center' }}>Container Nums</th>
                                <th style={{ textAlign: 'center' }}>Min Date</th>
                                <th style={{ textAlign: 'center' }}>External Id</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.jobs.map(
                                (job, index) => {
                                    return <tr key={index}>
                                        <td style={{ paddingLeft: '16px' }}>{index + 1}</td>
                                        <td><input className="form-control" name={index + "-carrier"} value={job.carrier} onChange={this.handleChange}></input></td>
                                        <td><input className="form-control" name={index + "-booking_ref"} value={job.booking_ref} onChange={this.handleChange}></input></td>
                                        <td><input className="form-control" name={index + "-bl_ref"} value={job.bl_ref} onChange={this.handleChange}></input></td>
                                        <td><input className="form-control" name={index + "-container_num"} value={job.container_num} onChange={this.handleChange}></input></td>
                                        <td><input className="form-control" name={index + "-min_date"} value={job.min_date} onChange={this.handleChange}></input></td>
                                        <td><input className="form-control" name={index + "-externalid"} value={job.externalid} onChange={this.handleChange}></input></td>
                                    </tr>
                                })
                            }

                        </tbody>
                    </table>
                    <div className="d-inline col-sm-4 offset-4" >
                        {/* style={{ marginTop: '10px', marginBottom: '3%', width: '98%' }} */}
                        <button className="btn btn-outline-primary mx-3 my-4" onClick={this.addEmptyJob}>+ Add New Job</button>
                        <button className="btn btn-outline-secondary mx-3" onClick={this.handleSubmit}>Save Jobs</button>
                        <button className="btn btn-outline-link mx-3 my-2" onClick={this.handleResetGrid} >Reset</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default InsertJobsGrid;