import * as CarrierCodes from './carriercodes';

export const apiContextUrl = '/api/fms/scraping/v2/rate';
export const apiContextUrlv1 = '/api/fms/scraping/v1/rate';
export const partnerContextUrl = '/api/fms/tracking/v1';

// Carrier micro service node port in GCP cluster in Stage and Prod environment.
export const carrierNodeports = {
    CCG: {
        stage: 32124,
        production: 31864,
        localhost: 8443
    },
    APL: {
        stage: 32086,
        production: 31626,
        localhost: 8442
    },
    EVL: {
        stage: 32217,
        production: 12345,
        localhost: 8441
    },
    MAE: {
        stage: 31786,
        production: 30652,
        localhost: 8444
    },
    SAF: {
        stage: 31786,
        production: 30652,
        localhost: 8444
    },
    SEAG: {
        stage: 31786,
        production: 30652,
        localhost: 8444
    },
    SLS: {
        stage: 31786,
        production: 30652,
        localhost: 8444
    },
    MCC: {
        stage: 31786,
        production: 30652,
        localhost: 8444
    },
  	ONE: {
        stage: 32389,
        production: 31881,
        localhost: 8443
    },
    EXT_PRT_API: {
        stage: 31197,
        production: 31290,
        localhost: 8080
    },
    ASYNC_COLLECTOR: {
        stage: 30998,
        production: 31888,
        localhost: 9999
    }
}

export const stageCarrierAPIUrls = [
    {
        name: CarrierCodes.CCG,
        url: `https://fmsratecarrierccg.stage.gcp.fms.de${apiContextUrl}/batchrate`
    },
  	{
        name: CarrierCodes.ONE,
        url: `https://fmsratecarrierone.stage.gcp.fms.de${apiContextUrlv1}/batchrate`
    },
    {
        name: CarrierCodes.APL,
        url: `https://fmsratecarrierapl.stage.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.EVL,
        url: `https://fmsratecarrierevl.stage.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.MSC,
        url: `https://fmsratecarriermsc.stage.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.MAE,
        url: `https://fmsratecarriermae.stage.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.SAF,
        url: `https://fmsratecarriermae.stage.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.SEAG,
        url: `https://fmsratecarriermae.stage.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.SLS,
        url: `https://fmsratecarriermae.stage.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.MCC,
        url: `https://fmsratecarriermae.stage.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.HLL,
        url: `https://fmsratecarrierhllapi.stage.gcp.fms.de${apiContextUrl}/batchrate`
    }
];


export const prodCarrierAPIUrls = [
    {
        name: CarrierCodes.CCG,
        url: `https://fmsratecarrierccg.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
  	{
        name: CarrierCodes.ONE,
        url: `https://fmsratecarrierone.prod.gcp.fms.de${apiContextUrlv1}/batchrate`
    },
    {
        name: CarrierCodes.APL,
        url: `https://fmsratecarrierapl.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.EVL,
        url: `https://fmsratecarrierevl.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.MSC,
        url: `https://fmsratecarriermsc.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.MAE,
        url: `https://fmsratecarriermae.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.SAF,
        url: `https://fmsratecarriermae.prod.gcp.fms.de${apiContextUrl}/$batchrate`
    },
    {
        name: CarrierCodes.SEAG,
        url: `https://fmsratecarriermae.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.SLS,
        url: `https://fmsratecarriermae.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.MCC,
        url: `https://fmsratecarriermae.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.HLL,
        url: `https://fmsratecarrierhllapi.prod.gcp.fms.de${apiContextUrl}/batchrate`
    }
];


export const devCarrierAPIUrls = [
    {
        name: CarrierCodes.CCG,
        url: `https://fmsratecarrierccg.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
  	{
        name: CarrierCodes.ONE,
        url: `https://fmsratecarrierone.prod.gcp.fms.de${apiContextUrlv1}/batchrate`
    },
    {
        name: CarrierCodes.APL,
        url: `https://fmsratecarrierapl.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.EVL,
        url: `https://fmsratecarrierevl.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.MAE,
        url: `https://fmsratecarriermae.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.SAF,
        url: `https://fmsratecarriermae.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.SEAG,
        url: `https://fmsratecarriermae.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.SLS,
        url: `https://fmsratecarriermae.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.MCC,
        url: `https://fmsratecarriermae.prod.gcp.fms.de${apiContextUrl}/batchrate`
    },
    {
        name: CarrierCodes.HLL,
        url: `https://fmsratecarrierhllapi.prod.gcp.fms.de${apiContextUrl}/batchrate`
    }
];

export const Auth_Token = 'FMS-ed378640-0dce-34cc-8bf7-9de3c105c92a';
export const FMS_TRACKINGS_AUTH_TOKEN = 'fms-98086055-94e1-3501-b57f-187b45959bac';

export const EXT_PRT_API_FETCH_DEV = `http://${window.location.hostname}:${carrierNodeports.EXT_PRT_API.localhost}${partnerContextUrl}/fetch/trackings`;
export const EXT_PRT_API_FETCH_STAGE = `https://fmscarrierpartnerapi.stage.gcp.fms.de${partnerContextUrl}/fetch/trackings`;
export const EXT_PRT_API_FETCH_PROD = `https://fmscarrierpartnerapi.prod.gcp.fms.de${partnerContextUrl}/fetch/trackings`;

export const EXT_PRT_API_INPUT_FETCH_DEV = `http://${window.location.hostname}:${carrierNodeports.EXT_PRT_API.localhost}${partnerContextUrl}/fetch/jobs`;
export const EXT_PRT_API_INPUT_FETCH_STAGE = `https://fmscarrierpartnerapi.stage.gcp.fms.de${partnerContextUrl}/fetch/jobs`;
export const EXT_PRT_API_INPUT_FETCH_PROD = `https://fmscarrierpartnerapi.prod.gcp.fms.de${partnerContextUrl}/fetch/jobs`


export const EXT_PRT_API_INPUT_INSERT_DEV = `http://${window.location.hostname}:${carrierNodeports.EXT_PRT_API.localhost}${partnerContextUrl}/partner`;
export const EXT_PRT_API_INPUT_INSERT_STAGE = `https://fmscarrierpartnerapi.stage.gcp.fms.de${partnerContextUrl}/partner`;
export const EXT_PRT_API_INPUT_INSERT_PROD = `https://fmscarrierpartnerapi.prod.gcp.fms.de${partnerContextUrl}/partner`

export const columnHeaders = [
    {
        Header: "MainHeader",
        columns: [

            {
                Header: "Id",
                accessor: "id"
            },
            {
                Header: "Carrier",
                accessor: "carrier"
            },
            {
                Header: "BK Ref",
                accessor: "bookingRef"
            },
            {
                Header: "BL Number",
                accessor: "blNumber"
            },
            {
                Header: "Container Num",
                accessor: "containerno"
            },
            {
                Header: "Status",
                accessor: "status"
            },
            {
                Header: "Status Date",
                accessor: "statusDate"
            },
            {
                Header: "Status Location",
                accessor: "statusLocation"
            },
            {
                Header: "Status Vessel",
                accessor: "statusVessel"
            },
            {
                Header: "Status Voyage",
                accessor: "statusVoyage"
            },
            {
                Header: "Processed Timestamp",
                accessor: "processedTimestamp"
            },
            {
                Header: "Shipment Id",
                accessor: "shipmentId"
            },
            {
                Header: "Creation Timestamp",
                accessor: "creationTimestamp"
            },
            {
                Header: "Partner Id",
                accessor: "extPartnerId"
            },
        ]
    }];




export const inputJobsColHeaders = [
    {
        Header: "MainHeader",
        columns: [
            {
                Header: "Id",
                accessor: "id"
            },
            {
                Header: "Carrier",
                accessor: "carrier"
            },
            {
                Header: "BK Ref",
                accessor: "bookingRef"
            },
            {
                Header: "BL Number",
                accessor: "blNumber"
            },
            {
                Header: "Container Nums",
                accessor: "containernos"
            },
            {
                Header: "Processing Timestamp",
                accessor: "processingTimestamp"
            },
            {
                Header: "Status",
                accessor: "status"
            },
            {
                Header: "Min Date",
                accessor: "minDate"
            },
            {
                Header: "Shipment Id",
                accessor: "shipmentId"
            },
            {
                Header: "Creation Timestamp",
                accessor: "creationTimestamp"
            },
            {
                Header: "Partner Id",
                accessor: "extPartnerId"
            },
            {
                Header: "Metadata",
                accessor: "metadata"
            },
        ]
    }];


export const INSERT_JOBS_HEADERS = ['carrier', 'booking_ref', 'bl_ref', 'container_num', 'min_date', 'externalid']

export const DATE_REGEX = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/