import React, { Component } from 'react';

class Surcharges extends Component {
    render() {
        return (
            <tr>
                <td>{this.props.surcharges.charge}</td>
                <td>{this.props.surcharges.chargeDescription}</td>
                <td>{this.props.surcharges.value}</td>
                <td>{this.props.surcharges.currency}</td>
                <td>{this.props.surcharges.paymentType}</td>
                <td>{this.props.surcharges.calculationBase}</td>
                <td>{this.props.surcharges.inclusive || 'false'}</td>
            </tr>
        );
    }
}

export default Surcharges;