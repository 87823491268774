import { INSERT_JOBS_HEADERS, DATE_REGEX } from "../../constants/appconstants";

const _ = require('lodash')
class Utils {
  validateFormInputs(ttsearchinputs) {
    let sPt = ttsearchinputs.startPt;
    let ePt = ttsearchinputs.endPt;
    let sCt = ttsearchinputs.startCt;
    let eCt = ttsearchinputs.endCt;
    let validationErrors = []
    if (sPt !== '' && ePt !== '') {
      if (Date.parse(sPt) > Date.parse(ePt)) {
        validationErrors.push("Processing date range is not valid")
      }
    }
    if (sCt !== '' && eCt !== '') {
      if (Date.parse(sCt) > Date.parse(eCt)) {
        validationErrors.push("Creation date range is not valid")
      }
    }
    if (sPt === '' && ePt !== '') {
      validationErrors.push("Start date is mandatory, when end date value is given for Processing Timestamp range.")
    }

    if (sCt === '' && eCt !== '') {
      validationErrors.push("Start date is mandatory, when end date value is given for Creation Timestamp range.")
    }


    return validationErrors;
  }

  validateExcelInputJobs(data) {
    let errors = [];
    let jsonData = [];

    if (data.length > 1) {
      if (data[0].length > INSERT_JOBS_HEADERS.length) {
        errors.push('Allowed columns: ' + INSERT_JOBS_HEADERS + ' (use same names)')
      } else {
        if (_.isEqual(data[0], INSERT_JOBS_HEADERS)) {
          //Validating input jobs
          jsonData = this.convertArraysToJson(data);

          errors = this.validateInputJobs(jsonData.slice(1));

        } else {
          errors.push('Column names should be: ' + INSERT_JOBS_HEADERS)
        }
      }
    } else {
      errors.push('No jobs found in the input file!');
    }

    return { errs: errors, dt: jsonData };
  }

  convertArraysToJson(data) {
    let json_data = [];

    data.map(
      (d, index) => {
        if (index !== 0) {
          var obj = {}
          data[0].forEach((key, i) => {
            obj[key] = d[i];
          });
          json_data.push(obj);
        }
        return null;
      }
    );
    // console.log(json_data)
    return json_data
  }


  validateInputJobs(data) {
    let errors = [];
    data.map((d, index) => {
      // if (index !== 0) {
      if (typeof d.carrier !== 'undefined' && d.carrier.trim() !== '') {
        if (d.carrier.length > 6) {
          errors.push('"carrier" code should be less than or equal to 6 characters; @row:' + (index + 1) + '; found: ' + d.carrier)
        }
      } else {
        errors.push('"carrier" code should not be empty; @row:' + (index + 1))
      }


      if (typeof d.externalid !== "undefined" && d.externalid !== '') {
        if (isNaN(d.externalid)) {
          errors.push('"externalid" should be a number; @row:' + (index + 1) + '; found: ' + d.externalid)
        }
      }


      if (typeof d.min_date !== "undefined" && d.min_date !== '') {
        if (!String(d.min_date).match(DATE_REGEX)) {
          errors.push('"min_date" should be in YYYY-MM-dd format; @row:' + (index + 1) + '; found: ' + d.min_date)
        }

        // }
      }
      return null;
    });
    return errors;
  }
}

export default new Utils();