const userDetails = [
    {
        uname: 'user1',
        pwd: 's3cr3t1@fms'
    },
    {
        uname: 'user2',
        pwd: 's3cr3t2@fms'
    },
    {
        uname: 'user3',
        pwd: 's3cr3t3@fms'
    },
    {
        uname: 'user4',
        pwd: 's3cr3t4@fms'
    },
    {
        uname: 'user5',
        pwd: 's3cr3t5@fms'
    }
    
];

class AuthenticationService {
    userLogin(user){
        let isAvailable = false;
        userDetails.forEach(
            u => {
                if(u.uname === user.uname && u.pwd === user.pwd){
                    isAvailable = true;
                }
            }
        )
        return isAvailable
    }

    userLogout(){
        sessionStorage.removeItem('uname')
    }

    isLoggedin(){
        return (sessionStorage.getItem('uname') !== null)
    }
}

export default new AuthenticationService()