export const ALL = 'all'
export const CCG = 'ccg'
export const APL = 'apl'
export const EVL = 'evl'
export const MAE = 'mae'
export const SAF = 'saf'
export const SEAG = 'seag'
export const SLS = 'sls'
export const MCC = 'mcc'
export const ONE = 'one'
export const MSC = 'msc'
export const HLL = 'hll'

export const SCAC_CODES = {
    mae: 'maeu',
    saf: 'safm',
    seag: 'sejj',
    sls: 'seau',
    mcc: 'mcpu'
}